import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import get from 'lodash/get'

// import 'prismjs/themes/prism-coy.css'
import 'prismjs/themes/prism-tomorrow.css'
import TwitterFollow from '../components/twitter-follow'

import DefaultLayout from '../components/layouts'

export default function Template(props) {
  const { data } = props
  const { markdownRemark, site } = props.data // data.markdownRemark holds our post data
  const { frontmatter, html, headings } = markdownRemark
  const sections = get(data, 'allSectionsJson.sections')
  const baseURL = data.site.siteMetadata.siteUrl

  return (
    <DefaultLayout siteMetadata={site.siteMetadata} {...props}>
      <div className="grid-container">
        <Helmet
          title={frontmatter.title}
          description={frontmatter.description}
          meta={[
            { name: 'twitter:dnt', content: 'on' },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@iosdevrecipes' },
            { name: 'twitter:creator', content: '@daveisonthego' },
            {
              name: 'twitter:title',
              content: `${frontmatter.title} | iOS Dev Recipes`,
            },
            {
              name: 'twitter:description',
              content: `${frontmatter.description}`,
            },
            {
              name: 'twitter:image',
              content: 'https://www.iosdev.recipes/img/logos/logo-2048.png',
            },
            {
              name: 'og:url',
              content: `${baseURL}${frontmatter.path}`,
            },
            {
              name: 'og:title',
              content: `${frontmatter.title} | iOS Dev Recipes`,
            },
            { name: 'og:description', content: `${frontmatter.description}` },
            {
              name: 'og:image',
              content: 'https://www.iosdev.recipes/img/logos/logo-2048.png',
            },
            { name: 'og:site_name', content: 'iOS Dev Recipes' },
          ]}
          htmlAttributes={{ lang: 'en-US' }}
        >
          <script type="application/ld+json" id="json-ld">
            {`
[
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "iOS Dev Recipes",
    "url": "${baseURL}",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.iosdev.recipes/img/logos/logo-1024.png",
      "width": 1024,
      "height": 1024
    },
    "sameAs": [
      "http://www.twitter.com/iosdevrecipes"
    ]
  },
  {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "${baseURL}${frontmatter.path}"
      },
      "headline": "${frontmatter.title}",
      "image": [
        "https://www.iosdev.recipes/img/logos/logo-1024.png"
      ],
      "datePublished": "${frontmatter.firstPublishedOn8601}",
      "dateModified": "${frontmatter.lastUpdatedDate8601}",
      "author": {
        "@type": "Person",
        "name": "Dave Lyon"
      },
      "publisher": {
        "@type": "Organization",
        "name": "iOS Dev Recipes",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.iosdev.recipes/img/logos/logo-1024.png"
        }
      },
      "description": "${frontmatter.description}"
  }
]
`}
          </script>
        </Helmet>

        <div className="grid-x grid-margin-x align-justify">
          <date className="cell">
            Last Updated: {frontmatter.lastUpdatedDate}
          </date>
          {/* <div className="cell align-right">
          <a
            className="twitter-share-button"
            href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.iosdev.recipes${
              frontmatter.path
            }&via=iosdevrecipes&hashtags=iosdev`}
            data-dnt="true"
            data-size="large"
          >
            Share this on Twitter
          </a>
        </div> */}
          {/* <div className="cell auto shrink">
          <TwitterFollow username="iosdevrecipes" />
        </div> */}
        </div>

        <article className="grid-x">
          <hr />
          <div
            className="cell content-page"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </article>
        <hr />
        <section className="grid-x grid-padding-x align-spaced">
          <div className="cell medium-5">
            <h2>There's more to read!</h2>
            <p className="lead">
              Did this help solve your problem? If so, consider{' '}
              <a href="https://twitter.com/iosdevrecipes/">
                letting us know on Twitter
              </a>
              . You should follow us for all the latest articles and updates:{' '}
            </p>
            <div className="cell">
              <p>
                <TwitterFollow username="iosdevrecipes" showCount="true" />
              </p>
            </div>
            <div className="cell">
              <TwitterFollow username="daveisonthego" showCount="true" />
            </div>
            <hr />
            <p className="lead">
              Have you seen our{' '}
              <a href="https://github.com/davelyon/ios-font-size-tester/">
                latest project on Github?
              </a>
              .
            </p>
          </div>
          <nav className="cell medium-6 more-to-read">
            <h2 className="underline">Our Other Articles</h2>
            <ol className="no-bullet listing">
              {data.allMarkdownRemark.posts.map(({ article }, idx) => (
                <li key={article.id} className="listing-item">
                  <Link
                    to={article.frontmatter.path}
                    title={article.frontmatter.linkTitle}
                    className="h4"
                  >
                    {article.frontmatter.linkTitle}
                  </Link>
                  <p>{article.frontmatter.summary}</p>
                </li>
              ))}
              <li className="listing-item see-more">
                <Link to="/articles/" className="h4">
                  All Articles
                </Link>
              </li>
            </ol>
          </nav>
        </section>
      </div>
    </DefaultLayout>
  )
}

export const pageQuery = graphql`
  query MDPageByPath($path: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }

    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        lastUpdatedDate
        lastUpdatedDate8601: lastUpdatedDate(
          formatString: "YYYY-MM-DDT00:00:00+08:00"
        )
        firstPublishedOn
        firstPublishedOn8601: firstPublishedOn(
          formatString: "YYYY-MM-DDT00:00:00+08:00"
        )
        isPublished
        isArticle
        title
        description
        summary
        linkTitle
        tags
      }
      headings {
        depth
        value
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { isArticle: { eq: true }, isPublished: { eq: true } }
      }
      sort: { fields: [frontmatter___firstPublishedOn], order: DESC }
      limit: 4
    ) {
      posts: edges {
        article: node {
          id
          frontmatter {
            path
            lastUpdatedDate
            lastUpdatedDate8601: lastUpdatedDate(
              formatString: "YYYY-MM-DDT00:00:00+08:00"
            )
            firstPublishedOn
            firstPublishedOn8601: firstPublishedOn(
              formatString: "YYYY-MM-DDT00:00:00+08:00"
            )
            isPublished
            isArticle
            title
            description
            summary
            linkTitle
            tags
          }
          timeToRead
        }
      }
    }
  }
`
